import intlTelInput from '../../_shared/vendor/intl-tel-input';
import {
    getIdPrefix,
    validateEmail,
    sendLeadBooking,
} from '../../_shared/popups/lead_popup_base';
import { selectRebuildOptions } from '../../_shared/common';

let tourBookingProperties;

tourBookingProperties = {
    dateToday: $('#tour_today_date').val()
};

if (
    $('#tour_booking_today_time_slot_hours').val() &&
    $('#tour_booking_week_time_slot_hours').val()
) {
    tourBookingProperties['tourBookingTodayTimeSlotHours'] = JSON.parse($("#tour_booking_today_time_slot_hours").val());
    tourBookingProperties['tourBookingWeekTimeSlotHours'] = JSON.parse($("#tour_booking_week_time_slot_hours").val());
}

let tourStartDateSelected = function (selectedDate) {
    if (
        !tourBookingProperties['dateToday'] ||
        (!tourBookingProperties['tourBookingTodayTimeSlotHours'] && !tourBookingProperties['tourBookingWeekTimeSlot'])
    ) {
        return;
    }
    let tourBookingTodayTimeSlotHours = [];
    tourBookingTodayTimeSlotHours =
        tourBookingProperties['tourBookingTodayTimeSlotHours'];

    if (tourBookingProperties['dateToday'] == selectedDate) {
        tourBookingTodayTimeSlotHours =
            tourBookingProperties['tourBookingTodayTimeSlotHours'];
    } else {
        let shortTodayDate = toDate(selectedDate)
            .toLocaleString('en-us', { weekday: 'short' })
            .toLowerCase();
        tourBookingTodayTimeSlotHours =
            tourBookingProperties['tourBookingWeekTimeSlotHours'][
                shortTodayDate
                ];
    }

    let tourTimeSlotSelect = $('#tour_time_slot_select').find('select');
    tourTimeSlotSelect.empty().append(`<option value="">Tour Time</option>`);
    $.each(tourBookingTodayTimeSlotHours, function (index, timeSlotHour) {
        tourTimeSlotSelect.append(
            `<option value="${timeSlotHour}">${timeSlotHour}</option>`
        );
    });

    tourTimeSlotSelect.val('');
    selectRebuildOptions(tourTimeSlotSelect);
    $('#tour_time_slot_select li').on('click', function () {
        $('#tour_time_slot').val($(this).attr('rel'));
    });
};

let checkOpeningTourDays = function(date){
    if (
        !tourBookingProperties['dateToday'] ||
        (!tourBookingProperties['tourBookingTodayTimeSlotHours'] && !tourBookingProperties['tourBookingWeekTimeSlot'])
    ) {
        return [true];
    }

    let currentDate = jQuery.datepicker.formatDate('mm/dd/yy', date);
    // let shortTodayDate = toDate(currentDate).toLocaleString('en-us', { weekday: 'short' }).toLowerCase();
    let tourBookingTodayTimeSlotHours = [];

    if (tourBookingProperties['dateToday'] == currentDate) {
        tourBookingTodayTimeSlotHours =
            tourBookingProperties['tourBookingTodayTimeSlotHours'];
    } else {
        let shortTodayDate = toDate(currentDate)
            .toLocaleString('en-us', { weekday: 'short' })
            .toLowerCase();
        tourBookingTodayTimeSlotHours =
            tourBookingProperties['tourBookingWeekTimeSlotHours'][shortTodayDate];
    }
    if(tourBookingTodayTimeSlotHours?.length > 1) {
        return [true];
    } else if(tourBookingTodayTimeSlotHours?.length == 1) {
        return [tourBookingTodayTimeSlotHours[0] !== "No available hours for this day"];
    } else {
        return [false];
    }
}

requestAnimationFrame(() => {
    import('../../_shared/vendor/jquery-ui-datepicker').then(() => {
        let form = document.getElementById('tour_form');
        if (!form) return;

        const todayDate = $.datepicker.parseDate('mm/dd/yy', tourBookingProperties.dateToday);
        $('#tour_form .datepicker').each(function () {
            let $this = $(this);
            // Override any previously set date pickers (TODO: this is super hacky, replace properly)
            if ($this.hasClass('hasDatepicker')) {
                $this.datepicker('destroy');
            }
            $this.datepicker({
                onSelect: $this.prop('id') !== 'tour_tour_start_date' ? undefined : tourStartDateSelected,
                minDate: todayDate,
                beforeShowDay: checkOpeningTourDays
            });
        });
    });
});

$(() => { setupTourForm(); });

let phoneInput;
async function setupTourForm() {
    let form = document.getElementById('tour_form');
    if (!form) return;

    $('.modal').on('show.bs.modal', function (event) {
        let phone = $('#tour_phone'),
            lPadd = phone.prev('.iti__flag-container').width() + 90;
        phone.css('padding-left', lPadd);
    });

    // hide validation errors on close
    $('.modal').on('hidden.bs.modal', function () {
        $(this).find('.validation-error').hide();
    });

    // handle bootstrap dropdown menus to be able to collects selected values
    $('#tour_resource_type_code_select li').on('click', function () {
        $('#tour_resource_type_code').val($(this).attr('rel'));
    });
    $('#tour_duration_metric_select li').on('click', function () {
        $('#tour_duration_metric').val($(this).attr('rel'));
    });
    $('#tour_duration_qty_select li').on('click', function () {
        $('#tour_duration_qty').val($(this).attr('rel'));
    });
    $('#tour_capacity_select li').on('click', function () {
        $('#tour_capacity').val($(this).attr('rel'));
    });
    $('#tour_time_slot_select li').on('click', function () {
        $('#tour_time_slot').val($(this).attr('rel'));
    });

    let idPrefix = getIdPrefix(form);
    var input = document.querySelector('#tour_phone');
    phoneInput = await intlTelInput(input, {
        allowDropdown: true,
        separateDialCode: true,
        autoHideDialCode: false,
        autoPlaceholder: 'off',
        dropdownContainer: document.body,
        excludeCountries: [''],
        formatOnDisplay: true,
        geoIpLookup: function (callback) {
            $.get('https://ipinfo.io', function () {}, 'jsonp').always(
                function (resp) {
                    let countryCode = resp && resp.country ? resp.country : '';
                    let city = resp && resp.city ? resp.city : '';
                    let region = resp && resp.region ? resp.region : '';
                    let ip = resp && resp.ip ? resp.ip : '';
                    let latLng = resp && resp.loc ? resp.loc : '';
                    var latLngParts = latLng.split(',');
                    $(idPrefix + '_user_country_code').val(countryCode);
                    $(idPrefix + '_user_city').val(city);
                    $(idPrefix + '_user_region').val(region);
                    $(idPrefix + '_user_ip').val(ip);
                    $(idPrefix + '_user_lat').val(latLngParts[0]);
                    $(idPrefix + '_user_lng').val(latLngParts[1]);
                    callback(countryCode);
                }
            );
        },
        hiddenInput: 'tour_full_number',
        initialCountry: 'auto',
        localizedCountries: {},
        nationalMode: false,
        onlyCountries: [],
        placeholderNumberType: 'MOBILE',
        preferredCountries: [],
        separateDialCode: true,
    });

    // setup submit button
    $(form).on('submit', function (e) {
        e.preventDefault();

        $(form).find('.validation-error').hide();
        let submitButton = $(form).find(':submit');
        submitButton.attr('disabled', true);
        if (validateTourForm(form)) {
            submitButton.attr('original-value', submitButton.attr('value'));
            submitButton.attr('value', 'Processing...');
            let params = getTourLeadFormData(form);
            sendLeadBooking(form, params);
        } else {
            submitButton.attr('value', submitButton.attr('original-value'));
            submitButton.attr('disabled', false);
        }
    });
}

function getTourLeadFormData(form) {
    let idPrefix = getIdPrefix(form);
    let phone = phoneInput.getNumber(intlTelInputUtils.numberFormat.E164);
    let phoneCountryCode = phoneInput.getSelectedCountryData().iso2;

    return {
        lead_type_code: $(idPrefix + '_lead_type_code').val(),
        capture_page: $(idPrefix + '_capture_page').val(),
        space_id: $(idPrefix + '_space_id').val(),
        user_id: $(idPrefix + '_user_id').val(),
        user_first_name: $(idPrefix + '_first_name').val(),
        user_last_name: $(idPrefix + '_last_name').val(),
        user_last_name_: $(idPrefix + '_last_name_').val(),
        user_name:
            $(idPrefix + '_first_name').val() +
            ' ' +
            $(idPrefix + '_last_name_').val(),
        user_email: $(idPrefix + '_email').val(),
        user_phone: phone,
        user_phone_country_code: phoneCountryCode,
        user_phone_country_id: $(idPrefix + '_phone_country_id').val(),
        user_company: $(idPrefix + '_company_name').val(),
        user_message: JSON.stringify($(idPrefix + '_message').val()),
        user_country_code: $(idPrefix + '_user_country_code').val(),
        user_city: $(idPrefix + '_user_city').val(),
        user_region: $(idPrefix + '_user_region').val(),
        user_ip: $(idPrefix + '_user_ip').val(),
        user_lat: $(idPrefix + '_user_lat').val(),
        user_lng: $(idPrefix + '_user_lng').val(),
        start_date: $(idPrefix + '_start_date').val(),
        tour_start_date: $(idPrefix + '_tour_start_date').val(),
        time_slot: $(idPrefix + '_time_slot').val(),
        resource_type_code: $(idPrefix + '_resource_type_code').val(),
        duration_metric: $(idPrefix + '_duration_metric').val(),
        duration_qty: $(idPrefix + '_duration_qty').val(),
        capacity: $(idPrefix + '_capacity').val(),
    };
}

function validateTourForm(form) {
    let hasErrors = false;
    let idPrefix = getIdPrefix(form);

    const $form = $(form);
    $form
        .find('input, select, textarea')
        .filter('[is_required]')
        .each(function (i, requiredField) {
            if ($.trim($(requiredField).val()) === '') {
                $(requiredField).siblings('.validation-error').show();
                hasErrors = true;
            }
        });

    let emailInput = $(idPrefix + '_email');
    if (emailInput.length > 0 && !validateEmail(emailInput.val())) {
        hasErrors = true;
        $(emailInput).siblings('.validation-error').show();
    }

    if (Math.abs(phoneInput.getValidationError()) > 0) {
        hasErrors = true;
        $('#enquiry_phone_error.validation-error').show();
    }

    const todayDate = $.datepicker.parseDate('mm/dd/yy', tourBookingProperties.dateToday);
    $form.find('.datepicker').each(function (i, dateField) {
        const $field = $(dateField);
        const dateText = $.trim($field.val());
        let enteredDate = null;
        try {
            enteredDate = $.datepicker.parseDate('mm/dd/yy', dateText);
        } catch (_) {
        }
        if (enteredDate == null || enteredDate < todayDate) {
            const $errors = $(dateField).siblings('.validation-error');
            $errors.text('Invalid date');
            $errors.show();
            hasErrors = true;
        }
    });

    if (!hasErrors) {
        return true;
    } else {
        return false;
    }
}
