<script lang="ts" context="module">
  declare global {
    interface Window {
      grecaptcha: any;
      dataLayer: any;
    }
  }

  const durationSelectItems = [
    {
      label: '1 day',
      value: 1,
    },
  ];
  for (let i = 2; i <= 30; i++) {
    durationSelectItems.push({
      label: `${i} days`,
      value: i,
    });
  }

  const emailRegex = /\S+@\S+\.\S+/;
</script>

<script lang="ts">
  import IMAGE_CROSS from '../_shared/common/img/img_cross.png?url';

  import Invalid from './Common/ValidationError.svelte';
  import Total from './BuyGlobalPass/Total.svelte';
  import FormStep2 from './BuyGlobalPass/FormStep2.svelte';

  import { fetchGeoIP } from '../_shared/fetch-geoip';
  import type { GeoIPResponse } from '../_shared/fetch-geoip';

  import type {
    HydrateJSON,
    FormFieldsStep1,
    FormData,
    Response,
  } from './BuyGlobalPass/types';
  import type { Plugin as IntlTelInput } from 'intl-tel-input';

  import { onDestroy, onMount } from 'svelte';
  // import Select from 'svelte-select';
  import DatePicker from 'svelte-flatpickr/src/Flatpickr.svelte';
  import '../_shared/flatpickr-theme.css';
  import intlTelInput from '../_shared/vendor/intl-tel-input';

  export let hydrate: HydrateJSON;
  export let onClose: () => void;
  export let onReady: (modal: HTMLDivElement) => void;
  export let onSuccess: (response: Response) => void;
  export let onError: (response?: Response) => void;

  let modal: HTMLDivElement;

  let geoip: GeoIPResponse | null;
  let phoneInput: IntlTelInput;

  onMount(async () => {
    geoip = await fetchGeoIP();
    phoneInput = await intlTelInput(form.phone, {
      allowDropdown: true,
      separateDialCode: true,
      autoHideDialCode: false,
      autoPlaceholder: 'off',
      dropdownContainer: document.body,
      excludeCountries: [''],
      formatOnDisplay: true,
      geoIpLookup: async (cb: (countryCode: any) => void) => {
        if (!geoip) {
          geoip = await fetchGeoIP();
        }
        cb(geoip?.country);
      },
      initialCountry: 'auto',
      localizedCountries: {},
      nationalMode: false,
      onlyCountries: [],
      placeholderNumberType: 'MOBILE',
      preferredCountries: [],
    });
    onReady(modal);
  });

  onDestroy(() => {
    phoneInput.destroy();
  });

  let step: 1 | 2 = 1;

  type FormField = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
  let form: Partial<Record<FormFieldsStep1, FormField>> = {};

  let value: Partial<Record<FormFieldsStep1, string>> = {};

  let startDate: Date | undefined;
  function handleStartDateChange(event: CustomEvent<[Date[], string]>) {
    const dates = event.detail[0];
    startDate = dates[0];
  }

  let invalid: Record<FormFieldsStep1, boolean> = {
    firstName: false,
    lastName: false,
    companyName: false,
    companyEmail: false,
    phone: false,
    startDate: false,
    timeSlot: false,
    message: false,
  };

  let numDays: number;
  let numGuests: number;
  $: numDays = 1;
  $: numGuests = 1;

  let total: number;
  $: total = numGuests * numDays * hydrate.globalPassPrice;

  let isSubmitting = false;
  // flatpickr and svelte-flatpickr do not directly support disabling, so the input field must be manually disabled
  $: if (form.startDate) {
    form.startDate.disabled = isSubmitting;
  }

  /**
   * @returns true if valid, false otherwise
   */
  function validateForm(): boolean {
    let isInvalid = false;
    const entries = Object.entries(form) as Array<[FormFieldsStep1, FormField]>;
    entries.forEach(([field, input]) => {
      value[field] = input.value.trim();
      if (input.dataset.required != null) {
        const empty = !value[field];
        isInvalid ||= empty;
        invalid[field] = empty;
      }
    });
    invalid.companyEmail =
      value.companyEmail == null || !emailRegex.test(value.companyEmail);
    isInvalid ||= invalid.companyEmail;
    invalid.phone = !phoneInput.isValidNumber();
    isInvalid ||= invalid.phone;

    return !isInvalid;
  }

  function handleContinue(): void {
    if (validateForm()) {
      step = 2;
    }
  }

  function handleBack(): void {
    step = 1;
  }

  function handleSubmit(stripeTokenId: string): void {
    isSubmitting = true;

    // At this stage, validation for required fields has been performed
    const values = value as Required<typeof value>;

    const data: FormData = {
      capture_page: hydrate.capturePageName,
      lead_type_code: 'buy_global_pass',
      resource_type_code: 'hot_desk',
      space_id: hydrate.spaceId,

      user_name: values.firstName + ' ' + values.lastName,
      user_last_name: '', // always set to be empty since there is no field
      user_email: values.companyEmail,
      user_company: values.companyName,
      user_phone: phoneInput.getNumber(),
      user_phone_country_code: phoneInput.getSelectedCountryData().iso2,

      capacity: '1',
      duration_metric: 'day',
      duration_qty: '1',
      start_date: values.startDate,
      time_slot: values.timeSlot,
      user_message: values.message,

      user_country_code: geoip?.country,
      user_city: geoip?.city,
      user_ip: geoip?.ip,
      user_lat: geoip?.latitude,
      user_lng: geoip?.longitude,
      user_region: geoip?.region,

      user_id: hydrate.userId,

      stripe_token: stripeTokenId,
    };

    window.grecaptcha.ready(async () => {
      const captchaToken = await window.grecaptcha.execute(
        '6LcaVKAUAAAAAAfDTPgFv8tGuwrodh_Mv6_eJ0oT',
        {
          action: 'handleStripeTokenResponse',
        }
      );
      try {
        const response = await fetch('/ajax/buy-global-pass/create', {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
            captcha_token: captchaToken,
          }),
        });
        const json = await response.json();
        if (response.status === 201) {
          onSuccess(json.data);
          window.dataLayer?.push({ event: 'buy_day_pass' });
        } else {
          onError(json);
        }
      } catch (err) {
        onError();
      } finally {
        isSubmitting = false;
      }
    });
  }
</script>

<div
  class="modal fade post-your-requirment-popup-outer buydaypass-popup-outer"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  bind:this={modal}
  on:click|preventDefault={onClose}
>
  <div class="modal-dialog modal-dialog-centered" role="document" on:click|stopPropagation>
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          aria-label="Close"
          on:click|preventDefault={onClose}
        >
          <span aria-hidden="true">
            <img src={IMAGE_CROSS} alt="cross icon" />
          </span>
        </button>
      </div>

      <div class="post-requirment-inner">
        <form
          on:submit|preventDefault={handleContinue}
          style={step == 2 ? 'display:none' : ''}
        >
          <div class="post-requirment-left-outer">
            <h2>Complete Your Global Pass Booking</h2>
            <div class="col-12 pad-none post-requirment-field-outer">
              <div class="post-requirment-left">
                <input
                  bind:this={form.firstName}
                  disabled={isSubmitting}
                  placeholder="First Name"
                  type="text"
                  data-required
                />
                <Invalid show={invalid.firstName}
                  >First name is required</Invalid
                >
              </div>
              <div class="post-requirment-right">
                <input
                  bind:this={form.lastName}
                  disabled={isSubmitting}
                  placeholder="Last Name"
                  type="text"
                  data-required
                />
                <Invalid show={invalid.lastName}>Last name is required</Invalid>
              </div>
            </div>

            <div class="col-12 pad-none post-requirment-field-outer">
              <div class="post-requirment-left">
                <input
                  bind:this={form.companyName}
                  disabled={isSubmitting}
                  placeholder="Company Name"
                  type="text"
                  data-required
                />
                <Invalid show={invalid.companyName} />
              </div>
              <div class="post-requirment-right">
                <input
                  bind:this={form.companyEmail}
                  disabled={isSubmitting}
                  placeholder="Company Email"
                  type="text"
                  data-required
                />
                <Invalid show={invalid.companyEmail}
                  >Valid email is required</Invalid
                >
              </div>
            </div>

            <div class="col-12 pad-none post-searching-space-outer ">
              <div class="col-12 pad-none post-requirment-field-outer">
                <div class="post-requirment-left">
                  <input
                    bind:this={form.phone}
                    type="tel"
                    placeholder="Phone Number"
                    data-required
                  />
                  <Invalid show={invalid.phone}
                    >Valid phone number is requried</Invalid
                  >
                </div>
                <div class="post-requirment-right">
                  <DatePicker
                    bind:input={form.startDate}
                    on:change={handleStartDateChange}
                    type="text"
                    placeholder="Start Date"
                    class="home-duration-input"
                    options={{
                      minDate: 'today',
                      disableMobile: true,
                    }}
                    data-required
                  />
                  <Invalid show={invalid.startDate} />
                </div>
              </div>

              <div class="col-12 pad-none post-requirment-field-outer">
                <div class="post-requirment-full">
                  <select
                    bind:this={form.timeSlot}
                    style="height: 46px;"
                    data-required
                  >
                    <option value="">Estimated Arrival Time</option>
                    {#each hydrate.timeSlotHours as timeSlotHour}
                      <option
                        value={timeSlotHour ===
                        'No available hours for this day'
                          ? ''
                          : timeSlotHour}>{timeSlotHour}</option
                      >
                    {/each}
                  </select>
                  <Invalid show={invalid.timeSlot} />
                </div>
              </div>

              <div class="col-12 pad-none post-requirment-field-outer">
                <textarea
                  bind:this={form.message}
                  placeholder="Message or additional requirements"
                  disabled={isSubmitting}
                />
                <Invalid show={invalid.message}>Message is required</Invalid>
              </div>

              <div
                class="col-12 pad-none post-requirment-field-outer complete-day-purchase-button"
              >
                <input type="submit" value="Continue" disabled={isSubmitting} />
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                <span
                  >You will not be charged until you confirm this booking on the
                  next screen</span
                >
              </div>
            </div>
          </div>
          <Total {hydrate} {total} {startDate} />
        </form>
        <FormStep2
          {isSubmitting}
          onSubmit={handleSubmit}
          onBack={handleBack}
          show={step === 2}
        >
          <Total {hydrate} {total} {startDate} />
        </FormStep2>
      </div>
    </div>
  </div>
</div>

<style>
  /*
  .select-override {
    --border: 1px solid #cad2d0;
    --borderRadius: 2px;
    --height: 46px;
    --inputFontSize: 13px;
    font-size: 13px;
    --inputColor: inherit;
    --placeholderColor: #aab3b1;
    --borderFocusColor: #00c78a !important;
    --borderHoverColor: #cad2d0;
    --itemHoverBG: #edf4f1;
    --itemIsActiveBG: #00927c;
  }

  .select-override :global(input:hover) {
    background: transparent !important;
  }

  .select-override :global(.selectContainer.focused) {
    box-shadow: 0 0 0 2px #dfede7 !important;
  }
  .select-override :global(.selectContainer input) {
    box-shadow: none !important;
  }
*/

  .modal :global(.iti) {
    font-size: 13px;
  }

  .modal :global(.iti--allow-dropdown input[type='tel']) {
    padding-left: 58px;
  }

  .modal-content {
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.26);
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .modal-header .close img {
    filter: brightness(0.4) drop-shadow(0 4px 13px rgba(0, 0, 0, 0.26));
  }
</style>
