<script lang="ts">
  import IMAGE_USER_ICON from "../../_shared/common/img/img_user_icon.png?url";
  import IMAGE_BUY_DAYPASS from "../Common/img/img_space_buydaypass.jpg?url";
  import IMAGE_CALENDAR from "../../_shared/common/img/img_calendar_icon.png?url";
  import IMAGE_ARROW from "../Common/img/img_buydaypass_arrow.svg?url";

  import { format as formatDate, addHours } from "date-fns";

  import type { HydrateJSON } from "./types";
  export let hydrate: HydrateJSON;

  export let subtotal: number = 0;
  export let startDate: Date = new Date();
  export let numHours: number = 0;
  export let numGuests: number = 0;
  export let numHoursPrice: number = 0;
  export let startTime: string = '';
  export let endTime: string = '';

  let total: number;
  $: total = subtotal;

  function weekday(date: Date) {
    return formatDate(date, "eee MMM d, y");
  }

  let startDateFormatted: string;
  let endDateFormatted: string;
  $: startDateFormatted = weekday(startDate);
  $: endDateFormatted = weekday(
    addHours(startDate, numHours)
  );

  function usd(amount: number) {
    const converted = amount;
    return `${converted.toFixed(2)} USD`;
  }
</script>

<div class="post-requirment-right-outer">
  <div class="buydaypass-space-con">
    <div class="buydaypass-left-con">
      <img loading="lazy" src={IMAGE_BUY_DAYPASS} alt="pic" />
    </div>
    <div class="buydaypass-right-con">
      <h3>{hydrate.spaceName}</h3>
      <div class="buydaypass-reviews-con">
        {#each { length: 5 } as _}
          <i class="fa fa-star" />
        {/each}
        <span>{hydrate.totalReviewCount} Reviews</span>
      </div>
    </div>
  </div>

  <div class="buydaypass-price-outer">
    <div class="buydaypass-time-con">
      <img src={IMAGE_CALENDAR} alt="calendar" />
      <span>{startDateFormatted}</span>
    </div>
    <div class="buydaypass-time-con startEndTime">
      <img src={IMAGE_CALENDAR} alt="calendar" />
      <span>Start Time {startTime}</span>
      <img src={IMAGE_ARROW} alt="arrow" class="buydaypass-arrow" />
      <span>End Time {endTime}</span>
    </div>

    <div class="buydaypass-price-panel">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">
          Global Pass {numGuests} x {numHoursPrice}
          USD x {numHours} {numHours === 1 ? "Hour" : "Hours"}
        </div>
        <div class="buydaypass-price-right">
          {usd(subtotal)}
        </div>
      </div>
    </div>

    <div class="buydaypass-total-outer">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">Total</div>
        <div class="buydaypass-price-right">
          {usd(subtotal)}
        </div>
      </div>
      <div class="buydaypass-price-con buydaypass-payable-now">
        <div class="buydaypass-price-left">Payable Now</div>
        <div class="buydaypass-price-right">
          {usd(total)}
        </div>
      </div>
    </div>
  </div>

  <div class="buydaypass-terms-con">
    <p>
      This payment will reserve your booking. You may cancel your booking up until 24 hours before your reservation date. Bookings cancelled within 24 hours of the reservation are non refundable.
    </p>
    <p>Prices on the next page will be converted into US dollars.</p>
  </div>
</div>
