<script lang="ts">
  import IMAGE_CROSS from "../_shared/common/img/img_cross.png?url";
  import IMAGE_CALENDAR from "../_shared/common/img/img_calendar_icon.png?url";
  import IMAGE_CHECK_CIRCLE from "../_shared/common/img/img_check_circle.png?url";

  import type { HydrateJSON } from "./BuyDayPass/types";

  import { onMount } from 'svelte';

  export let onClose: () => void;
  export let onReady: (modal: HTMLDivElement) => void;

  export let hydrate: HydrateJSON;
  export let title: string = '';
  export let message: string = '';
  export let genericMessage: string = '';
  export let responseData: {
    amount_paid_usd: string,
    start_date_readable: string,
    resource_type_name: string,
    capacity_readable: string,
    duration_readable: string,
  };
  export let booking: boolean = false;
  export let bookingMessage: string = '';

  let modal: HTMLDivElement;

  onMount(() => {
    onReady(modal);
  })
</script>

<style>
  .modal-content {
    box-shadow: 0 4px 13px rgba(0,0,0,.26);
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .modal-header .close img {
    filter: brightness(0.4) drop-shadow(0 4px 13px rgba(0,0,0,.26));
  }
</style>

<div
  class="modal fade booking-enquiry-message-popup-main"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  bind:this={modal}
  on:click|preventDefault={onClose}
>
  <div class="modal-dialog modal-dialog-centered" role="document" on:click|stopPropagation>
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          aria-label="Close"
          on:click|preventDefault={onClose}
        >
          <span aria-hidden="true">
            <img src={IMAGE_CROSS} alt="close" />
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="booking-enquiry-message-main-outer">
          <h3>{title}</h3>
          <div class="booking-enquiry-green-outer">
            <div class="booking-circle-img">
              <img src={IMAGE_CHECK_CIRCLE} alt="success" />
            </div>
            <div class="booking-sent-message">
              {message}
            </div>
            <span>
              <img
                src={IMAGE_CALENDAR}
                alt="calender"
                style="display: inline;"
              />
              <span style="display: inline; text-decoration: underline;">
                {responseData.start_date_readable}
              </span>
            </span>
          </div>
          <div class="Booking-meeting-room-outer">
            <h4>{responseData.resource_type_name}</h4>
            <div class="booking-meeting-room-con">
              <span>{responseData.capacity_readable}</span>
              <span>{responseData.duration_readable}</span>
            </div>
            <div>
              {#if booking}
              <p>
                {bookingMessage}
              </p>
              {:else}
              <p>
                {hydrate.spaceName} will be in touch with you soon to confirm
              </p>
              {/if}
            </div>
            <div>
              {#if genericMessage}
                <p>genericMessage</p>
              {/if}
            </div>
          </div>

          {#if ( hydrate.facebookUrl || hydrate.twitterUrl || hydrate.instagramUrl ) && hydrate.isPro }
            <div class="booking-enquiry-social-con">
              <p>
                Want to invite friends or colleagues to check out {hydrate.spaceName} too?
              </p>
              {#if hydrate.facebookUrl}
                <a href={hydrate.facebookUrl} target="_blank" rel="noopener">
                  <i class="fa fa-facebook" aria-hidden="true" />
                </a>
              {/if}
              {#if hydrate.twitterUrl}
                <a href={hydrate.twitterUrl} target="_blank" rel="noopener">
                  <i class="fa fa-twitter" aria-hidden="true" />
                </a>
              {/if}
              {#if hydrate.instagramUrl}
                <a href={hydrate.instagramUrl} target="_blank">
                  <i class="fa fa-instagram" aria-hidden="true" />
                </a>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
