import type {} from 'svelte'; // Allow Svelte compontents to be handled correctly with TypeScript

import BookingServiceModal from '../../components/BookingService.svelte';
import ModalBookingSuccess from '../../components/ModalBookingSuccess.svelte';
import ModalBookingTryAgain from '../../components/ModalBookingTryAgain.svelte';

import type {HydrateJSON, ResourceBooking, Response} from '../../components/BookingService/types';

const hydrate: HydrateJSON | undefined = window.hydrateJSON;

function modal(el: HTMLElement, state: 'hide' | 'show')  {
    // @ts-ignore There are no bindings for Bootstrap modal usage
    $(el).modal(state);

    if (state === 'show') {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';
    } else {
        document.body.style.overflow = 'auto';
        document.body.style.overflowX = 'hidden';
        document.body.style.paddingRight = '0';
    }
}

function onModalHidden(el: HTMLElement, cb: () => void)  {
    // @ts-ignore There are no bindings for Bootstrap modal usage
    $(el).on('hidden.bs.modal', cb);
}

if (!hydrate) {
  console.log('hydrate missing');
}

let bookingServiceElement: HTMLDivElement;
let successElement: HTMLDivElement;
let tryAgainElement: HTMLDivElement;

let successModal: ModalBookingSuccess | undefined;
function openSuccessModal(props: Record<string, any> | undefined) {
  successModal = new ModalBookingSuccess({
    target: document.body,
    props: {
      hydrate,
      ...props,

      onReady(el: HTMLDivElement) {
        successElement = el;
        onModalHidden(successElement, () => {
        })
        modal(successElement, 'show');
      },
      onClose() {
        modal(successElement, 'hide');
      },
    }
  });
}

let tryAgainModal: BookingServiceModal | undefined;
function openTryAgainModal(props: Record<string, any> | undefined) {
  tryAgainModal = new ModalBookingTryAgain({
    target: document.body,
    props: {
      hydrate,
      ...props,

      onReady(el: HTMLDivElement) {
        tryAgainElement = el;
        onModalHidden(tryAgainElement, () => {
          tryAgainModal?.$destroy();
        })
        modal(tryAgainElement, 'show');
      },
      onClose() {
        modal(tryAgainElement, 'hide');
      },
    }
  });

}



let bookingServiceModal: BookingServiceModal | undefined;
export function openBookingServiceModal(resource: ResourceBooking) {
  if (bookingServiceModal != null) return;
  bookingServiceModal = new BookingServiceModal({
    target: document.body,
    props: {
      hydrate,
      resource,
      onReady(el: HTMLDivElement) {
        bookingServiceElement = el;
        onModalHidden(bookingServiceElement, () => {
          bookingServiceModal?.$destroy();
          bookingServiceModal = undefined;
        })
        modal(bookingServiceElement, 'show');
      },
      onClose() {
        modal(bookingServiceElement, 'hide');
      },
      onSuccessSearch(responseData: Response) {
        
      },
      onSuccess(responseData: Response) {
        modal(bookingServiceElement, 'hide');

        openSuccessModal({
          title: `Your Meeting Room Booking is Confirmed`,
          message: `Your booking at ` + hydrate.spaceName + `has been confirmed and paid in full.`,
          booking: true,
          bookingMessage: `Please check your email to view your booking details.`,
          responseData,
        })
      },
      onError(responseData?: Response) {
        openTryAgainModal({
          technicalError: responseData == null,
          responseData: responseData == null ? {} : responseData
        });
      },
    }
  });
}