<script lang="ts">
  import IMAGE_USER_ICON from "../../_shared/common/img/img_user_icon.png?url";
  import IMAGE_BUY_DAYPASS from "../Common/img/img_space_buydaypass.jpg?url";
  import IMAGE_CALENDAR from "../../_shared/common/img/img_calendar_icon.png?url";
  import IMAGE_ARROW from "../Common/img/img_buydaypass_arrow.svg?url";

  import { format as formatDate, addBusinessDays } from "date-fns";

  import type { HydrateJSON } from "./types";
  export let hydrate: HydrateJSON;

  export let subtotal: number = 0;
  export let startDate: Date = new Date();
  export let numDays: number = 0;
  export let numGuests: number = 0;

  let deposit: number;
  let total: number;
  $: deposit = 0.15 * subtotal;
  $: total = subtotal - deposit;

  function weekday(date: Date) {
    return formatDate(date, "eee MMM d, y");
  }

  function getFixedDate( startDate, numDays ) {
    numDays = numDays > 1 ? numDays - 1 : numDays
    return weekday( addBusinessDays( startDate, numDays ) )
  }

  let startDateFormatted: string;
  let endDateFormatted: string;
  $: startDateFormatted = weekday(startDate);
  $: endDateFormatted = getFixedDate(startDate, numDays);
  //$: endDateFormatted = getFixedDate(startDate, numDays);

  function usd(amount: number) {
    const converted = amount / hydrate.currencyToUsdValue;
    return `${converted.toFixed(2)} USD`;
  }
</script>

<div class="post-requirment-right-outer">
  <div class="buydaypass-space-con">
    <div class="buydaypass-left-con">
      <img loading="lazy" src={IMAGE_BUY_DAYPASS} alt="pic" />
    </div>
    <div class="buydaypass-right-con">
      <h3>{hydrate.spaceName}</h3>
      <div class="buydaypass-reviews-con">
        {#each { length: 5 } as _}
          <i class="fa fa-star" />
        {/each}
        <span>{hydrate.totalReviewCount} Reviews</span>
      </div>
    </div>
  </div>

  <div class="buydaypass-price-outer">
    <div class="buydaypass-guest-con">
      <img src={IMAGE_USER_ICON} alt="pic" />
      <span>{numGuests} Guest{numGuests === 1 ? '' : 's'}</span>
    </div>
    <div class="buydaypass-time-con">
      <img src={IMAGE_CALENDAR} alt="calendar" />
      <span>{startDateFormatted}</span>
      {#if numDays > 1}
      <img src={IMAGE_ARROW} alt="arrow" class="buydaypass-arrow" />
      <span>{endDateFormatted}</span>
      {/if}
    </div>

    <div class="buydaypass-price-panel">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">
          Day Pass {numGuests} x {hydrate.buyDayPassPrice}
          {hydrate.currencyCode} x {numDays} {numDays === 1 ? "Day" : "Days"}
        </div>
        <div class="buydaypass-price-right">
          {usd(subtotal)}
        </div>
      </div>
    </div>

    <div class="buydaypass-deposit-outer">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">Deposit</div>
        <div class="buydaypass-price-right">
          {usd(deposit)}
        </div>
      </div>
    </div>

    <div class="buydaypass-total-outer">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">Total</div>
        <div class="buydaypass-price-right">
          {usd(subtotal)}
        </div>
      </div>
      <div class="buydaypass-price-con buydaypass-payable-now">
        <div class="buydaypass-price-left">Payable Now</div>
        <div class="buydaypass-price-right">
          {usd(deposit)}
        </div>
      </div>
    </div>
  </div>

  <div class="buydaypass-terms-con">
    <p>
      The amount payable now is a non-refundable deposit to reserve your day
      pass. The balance of <strong>{usd(total)} +</strong> applicable taxes is payable
      on arrival at the property, in the property's currency.
    </p>
    <p>Prices on the next page will be converted into US dollars.</p>
  </div>
</div>
