<script lang="ts" context="module">
  import { loadStripe } from '@stripe/stripe-js';
  import type {
    StripeCardElement,
    StripeElementChangeEvent,
    Stripe,
    CreateTokenCardData,
    TokenResult,
  } from '@stripe/stripe-js';
  import CardElement from './StripeCardInput/CardElement.svelte';

  let stripe: Stripe | null = null;
  const stripePromise = window?.hydrateJSON?.stripePublicKey
    ? loadStripe(window.hydrateJSON.stripePublicKey)
    : Promise.resolve(null);

  stripePromise.then((_stripe) => (stripe = _stripe));

  export async function createToken(
    el: StripeCardElement,
    data?: CreateTokenCardData
  ): Promise<TokenResult> {
    const stripe = await stripePromise;
    return stripe!.createToken(el, data);
  }
  
  export interface StripeValidationError {
    type: 'validation_error';
    code: string;
    message: string;
  }
  
  export type { Token as StripeToken, StripeCardElement, StripeError } from '@stripe/stripe-js';
</script>

<script lang="ts">
  let setup = false;
  export let complete = false;
  export let empty = true;
  export let invalid: StripeValidationError | undefined = undefined;

  export let cardElement: StripeCardElement | undefined;

  function handleChange(event: StripeElementChangeEvent) {
    complete = event.complete;
    empty = event.empty;
    invalid = event.error;
  }

  $: {
    if (cardElement && !setup) {
      setup = true;
      cardElement.on('change', handleChange);
    }
  }
</script>

{#if stripe}
  <CardElement {stripe} bind:cardElement />
{:else}
  <input type="text" disabled />
{/if}
