<script lang="ts">
  import { onDestroy } from 'svelte';
  import type {
    Stripe,
    StripeCardElement,
    StripeElements,
  } from '@stripe/stripe-js';

  export let stripe: Stripe | null;
  export let cardElement: StripeCardElement | undefined;
  let elements: StripeElements | undefined;
  let mountPoint: HTMLDivElement | undefined;

  $: {
    if (stripe != null && mountPoint != null && cardElement == null) {
      elements = stripe.elements();
      cardElement = elements.create('card', {
        style: {
          base: {
            fontFamily: 'Work Sans, Open Sans, Segoe UI, sans-serif',
            fontSize: '13px',
            "::placeholder": {
              color: '#AAB3B1'
            }
          }
        }
      });
      cardElement.mount(mountPoint);
    }
  }

  onDestroy(() => {
    if (cardElement) {
      cardElement.clear();
      cardElement.unmount();
    }
  });
</script>

<style>
  label {
    display: block
  }
  
  label :global(.StripeElement) {
    float: left;
    width: 100%;
    background: #fff;
    border: 1px solid #cad2d0;
    box-sizing: border-box;
    border-radius: 2px;
    color: #464c4b;
    padding: 15px 10px;
    height: 46px;
  }
  
  label :global(.StripeElement--focus) {
    border-color: #00c78a;
    box-shadow: 0 0 0 2px #dfede7;
  }

  label:hover :global(.StripeElement--focus) {
    background: #f1f5f4;
  }
</style>

<!-- svelte-ignore a11y-label-has-associated-control -->
<label>
  <div bind:this={mountPoint} />
</label>
