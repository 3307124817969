<script lang="ts">
  import { onMount } from 'svelte';

  import IMAGE_TRY_AGAIN from '../_shared/common/img/img_cross_try_again.png?url';
  import IMAGE_REFRESH from '../_shared/common/img/img_refresh_icon.png?url';

  export let onClose: () => void;
  export let onReady: (modal: HTMLDivElement) => void;
  export let reason: string = 'This can happen for a few different reasons.';
  export let technicalError = false;
  export let responseData: {
    error: boolean,
    message: string
  };
  let modal: HTMLDivElement;
  onMount(() => {
    onReady(modal);
  })
</script>

<style>
  .modal-content
  {
    box-shadow: 0 4px 13px rgba(0,0,0,.26);
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .complete-try-again-button img {
    filter: brightness(0.4) drop-shadow(0 4px 13px rgba(0,0,0,.26));
  }
</style>

<div
  class="modal fade complete-daypass-try-popup"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  bind:this={modal}
  on:click|preventDefault={onClose}
>
  <div class="modal-dialog modal-dialog-centered" role="document" on:click|stopPropagation>
    <div class="modal-content">
      <div class="complete-try-modal-content">
        <div class="modal-header">
        </div>
        <div class="complete-daypass-try-popup">
          <div class="complete-try-cross-icon">
            <img src={IMAGE_TRY_AGAIN} alt="failure - try again icon" />
          </div>
          {#if technicalError}
            <h2>Try Again</h2>
            <p>
              Oops! Something went wrong while processing your order.<br />
              Please contact hello@coworker.com for help or click the try again button to retry.
            </p>
          {:else }
            {#if responseData.error !== undefined && responseData.error }
              {#if responseData.message == null }
                <h2>Try Again</h2>
                <p>
                  Oops! Something went wrong while processing your order.<br />
                  Please contact hello@coworker.com for help or click the try again button to retry.
                </p>
              {:else}
                <h2>No Availability</h2>
                <p>{responseData.message}</p>
              {/if}
            {:else}
              <h2>Try Again</h2>
              <p>
                Oh no! Your payment was declined. {reason}<br />
                Please call your bank to find out why or click the try again button to retry.
              </p>
            {/if}
          {/if}
          <div class="complete-try-again-button">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a href="#" on:click|preventDefault={onClose}>
              <img src={IMAGE_REFRESH} alt="refresh icon" />
              Try Again
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
