// import "vite/dynamic-import-polyfill";

import '../../_shared/common';
import '../../_shared/vendor/bootstrap';
import '../../_shared/vendor/font-awesome';

import '../../_shared/common/common.purged.css';
import '../../_shared/common/style-res.purged.css';
import '../../_shared/photo_banner';
import './review_paginaton';
import './lead_popup_space_enquiry';
import './lead_popup_tour';
// import './lead_popup_buy_daypass';
import '../../_shared/popups/lead_popup_post_requirements';
import { loadSearchAutocompleteMenu } from '../../_shared/vendor/algolia';
import { openBuyDayPassModal } from './buyDayPass';
import { openBuyGlobalPassModal } from './buyGlobalPass';
import { openBookingServiceModal } from './bookingService';
import { openBuyLoungePassModal } from './buyLoungePass';
import { openBuyHotDeskModal } from "./buyHotDesk";

import './profile.css';

window.Page = {};
Page.baseUrl = $('#base_url').val();
Page.cloudFrontUrl = $('#cloudfront_url').val();
Page.countryCities = JSON.parse($('#cities').val());

if (window.hydrateJSON?.isBuyDayPassEnabled) {
    import('./buyDayPass').then(buyDayPass => {
        document.querySelectorAll('.button--buy-day-pass').forEach(el => {
            el.addEventListener('click', (ev) => {
                ev.preventDefault()
                openBuyDayPassModal();
            })
        });
    })
    import('./buyHotDesk').then(buyHotDesk => {
        document.querySelectorAll('.button--buy-hot-desk').forEach(el => {
            el.addEventListener('click', (ev) => {
                let data = JSON.parse( el.dataset.hotDesk )
                ev.preventDefault()
                openBuyHotDeskModal( data );
            })
        })
    })
}

if (window.hydrateJSON?.isBuyGlobalPassEnabled) {
    import('./buyGlobalPass').then(buyGlobalPass => {
        document.querySelectorAll('.blackGoldenBtn').forEach(el => {
            el.addEventListener('click', (ev) => {
                ev.preventDefault()
                openBuyGlobalPassModal();
            })
        });
    })
}

import('./bookingService').then(bookingService => {
    document.querySelectorAll('.button--iwg-booking-service').forEach(el => {
        el.addEventListener('click', (ev) => {
            ev.preventDefault();

            let resourceBooking = {};
            resourceBooking.mrId = el.getAttribute("data-mr-id");
            resourceBooking.mrName = el.getAttribute("data-mr-name");
            resourceBooking.mrCapacity = el.getAttribute("data-mr-capacity");
            openBookingServiceModal(resourceBooking);
        })
    });
})

if (window.hydrateJSON?.isBuyLoungePassEnabled) {
    import('./buyLoungePass').then(buyLoungePass => {
        document.querySelectorAll('.button--buy-lounge-pass').forEach(el => {
            el.addEventListener('click', (ev) => {
                ev.preventDefault()
                openBuyLoungePassModal();
            })
        });
    })
}

const lazyShowMore = {};
function setupDescription(id) {
    $('.space-full-description-inner').hide();
    const $description = $(
        '.space-full-description-inner[data-id="' + id + '"]'
    );
    $description.show();
    $('.space-description-con .space-see-more').hide();
    // If the element is overflowing, we'll want to show the see more button
    const $seeMore = $(
        '.space-description-con .space-see-more[data-id="' + id + '"]'
    );
    if (
        $description.prop('scrollHeight') > $description.height() ||
        $description.hasClass('expanded')
    ) {
        $seeMore.show();
    }
    if (lazyShowMore[id]) return;
    lazyShowMore[id] = true;
    $seeMore.children('a').on('click', function (evt) {
        evt.preventDefault();
        $description.toggleClass('expanded');
    });
}
setupDescription(0);

// LANGUAGE TAB
$('.space-language-tab').on('click', function (evt) {
    $('.space-language-tab').removeClass('space-language-active');
    $(this).addClass('space-language-active');
    let id = this.dataset['id'];
    setupDescription(id);
});

//SPACE PAGE OVERVIEW NAVBAR FIXED END ON FOOTER
let floatingNavbarHandler = floatingNavbar();
function floatingNavbar() {
    let spaceNavbarAnimation;

    const navbar = document.getElementById('space-navbar');
    const firstDiv = document.getElementById('header') || document.getElementById('old_header');
    const lastDiv = document.getElementById('content_end') || document.querySelector('.space-empty-section');

    let fixSet = false;

    function isWithinDivs() {
        if (!firstDiv || !lastDiv) return false;
        const firstDivBounds = firstDiv ? firstDiv.getBoundingClientRect() : null;
        const lastDivBounds = lastDiv ? lastDiv.getBoundingClientRect() : null;

        if (firstDivBounds && lastDivBounds) {
            const firstDivTop = firstDivBounds.top + window.pageYOffset;
            const lastDivBottom = lastDivBounds.bottom + window.pageYOffset;

            return window.pageYOffset > firstDivTop && window.pageYOffset < lastDivBottom;
        } else {
            return false;
        }
    }

    return function () {
        if (spaceNavbarAnimation != null) {
            cancelAnimationFrame(spaceNavbarAnimation);
        }

        spaceNavbarAnimation = requestAnimationFrame(() => {
            if (isWithinDivs()) {
                if (fixSet) return;
                navbar.classList.add('space-fixed');
                fixSet = true;
            } else if (fixSet) {
                fixSet = false;
                navbar.classList.remove('space-fixed');
            }
        });
    };
}
window.addEventListener('scroll', floatingNavbarHandler, { passive: true });
window.addEventListener(
    'resize',
    () => {
        floatingNavbarHandler = floatingNavbar();
        floatingNavbarHandler();
    },
    { passive: true }
);
floatingNavbarHandler();

// scroll into reviews by reviews next to the title click
$(".reviews-count-outer").click(function() {
    $('html, body').animate({
        scrollTop: $("#review_results").offset().top - 100
    }, 1000);
});

// #amenitiesModal show/hide
$('.show-more:not(.reviews)').on('click', function(e) {
    e.preventDefault();
    const amenitiesModal = $('#amenitiesModal');
    if (amenitiesModal.length) {
        amenitiesModal.css('display', 'block');
        $('body').css({
            'overflow': 'hidden',
            'padding-right': '15px'
        });
    }
});

// #reviewsModal show/hide
$('.show-more.reviews').on('click', function(e) {
    e.preventDefault();
    const reviewsModal = $('#reviewsModal');
    if (reviewsModal.length) {
        reviewsModal.css('display', 'block');
        $('body').css({
            'overflow': 'hidden',
            'padding-right': '15px'
        })
    }
})

$('#amenitiesModal, #amenitiesModal .close, #reviewsModal, #reviewsModal .close').on('click', function(e) {
    e.preventDefault();
    $('#amenitiesModal').css('display', 'none');
    $('#reviewsModal').css('display', 'none');
    $('body').css({
        'overflow': 'auto',
        'padding-right': '0px'
    });
});

$('#amenitiesModal .modal-content').on('click', function(e) {
    e.stopPropagation();
})
$('#reviewsModal .modal-content').on('click', function(e) {
    e.stopPropagation();
})
// #amenitiesModal/#reviewsModal show/hide end

//smoothscroll
$('#space-navbar a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    document.removeEventListener('scroll', handleScroll);

    $('#space-navbar a[href^="#"]').each(function () {
        $(this).removeClass('space-navbar-active');

        /*var targetDivId = this.getAttribute('href');
        $(targetDivId).removeClass('highlighted');*/
    });
    $(this).addClass('space-navbar-active');
    const $spaceNavbar = $('#space-navbar.space-fixed');
    const $header = $('.header-inner-pages');
    let offset =
        ($spaceNavbar.length === 0
            ? $('#space-navbar').height()
            : $spaceNavbar.position().top) + $header.height();

    // Add an additional offset if viewport width is less than X
    if ($(window).width() < 900) {
        offset += 40;
    } else if ($(window).width() < 500) {
        offset += 20;
    }

    const target = this.getAttribute('href');
    const $target = $(target);
    const targetLoc = this.href;
    $('html, body')
        .stop()
        .animate(
            {
                scrollTop: $target.offset().top - offset,
            },
            500,
            'swing',
            function () {
                history.replaceState({}, '', targetLoc);
                document.addEventListener('scroll', handleScroll, {
                    passive: true,
                });
                handleScroll();
            }
        );

    /*$target.addClass('highlighted');*/
});

const links = document.querySelectorAll('.mCustomScrollBox a');

// switch 'highlighted' when <a> tag has 'space-navbar-active' class
const callback = function(mutationsList, observer) {
    for(let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const target = mutation.target;
            const href = target.getAttribute('href');
            const $div = $(href);

            /*if (target.classList.contains('space-navbar-active')) {
                $div.addClass('highlighted');
            } else {
                $div.removeClass('highlighted');
            }*/
        }
    }
};

const observer = new MutationObserver(callback);
const config = { attributes: true, subtree: true };
links.forEach(link => observer.observe(link, config));

// calculate 2 lowest prices for #sidebar as in list/map results
const getSinglePrice = (private_office) => {
    const result = private_office.reduce((minPriceObj, currentObj) => {
      // If the current object has a lower price and the same duration_metric, update minPriceObj
      if (
        currentObj.duration_metric === 'month'
        && (minPriceObj === null || currentObj.price < minPriceObj.price)
      ) {
        return {
          name: currentObj.name,
          currency_code: currentObj.currency_code,
          price: currentObj.price,
          duration_metric: currentObj.duration_metric,
        };
      }

      return minPriceObj;
    }, null);

    return result;
  };
const getMonthlyPrices = (memberships) => {
    const order = [
      'private_offices', 'hot_desks', 'dedicated_desks', 'meeting_rooms', 'virtual_offices',
    ];
    const names = {
      private_offices: 'Serviced Office',
      hot_desks: 'Coworking Space',
      dedicated_desks: 'Coworking Space',
      meeting_rooms: 'Meeting rooms',
      virtual_offices: 'Virtual offices',
    };

    const monthlyPrices = order
      .map((key) => {
        if (memberships && memberships[key]) {
          if (Array.isArray(memberships[key])) {
            const monthly = getSinglePrice(memberships[key]);
            if (monthly) {
              return { ...monthly, name: names[key] };
            }
          } else if (key === 'virtual_offices' && Object.prototype.hasOwnProperty.call(memberships[key], 'price')) {
            return { ...memberships[key], name: names[key], duration_metric: 'month' };
          }
        }

        return null;
      })
      .filter((item) => item !== null)
      .slice(0, 2);

    const uniqueMonthlyPrices = monthlyPrices.reduce((uniquePrices, currentPrice) => {
      const existingIndex = uniquePrices.findIndex((item) => item.name === currentPrice.name);

      if (existingIndex === -1) {
        // If the name doesn't exist yet, add the current price object to the array
        uniquePrices.push(currentPrice);
      } else if (currentPrice.price < uniquePrices[existingIndex].price) {
        // If the current price is lower than the existing one, update the existing object
        uniquePrices[existingIndex] = currentPrice;
      }

      return uniquePrices;
    }, []);

    return uniqueMonthlyPrices;
};

const sidebarPrices = getMonthlyPrices(window.hydrateJSON.memberships)

$.each(sidebarPrices, function(index, el) {
    var htmlContent = '<div class="price_container">' +
        '<span>' + el.name + '</span>' +
        '<div><span>from&nbsp;</span><p>' + (window.hydrateJSON.currencySymbol || el.currency_code) + '' + el.price + '</p>' +
        '<span>/' + (el.duration_metric || 'month') + '</span></div>' +
        '</div>';

    if (index === 0) {
        $('.first_price').html(htmlContent);
    } else if (index === 1) {
        $('.second_price').html(htmlContent);
    }
});

//SPACE RIGHT BAR FIXED END ON FOOTER
// $(function () {
//     var top =
//         $('#sidebar').offset().top -
//         parseFloat($('#sidebar').css('marginTop').replace(/auto/, 0));
//     var footTop =
//         $('.space-empty-section ').offset().top -
//         parseFloat(
//             $('.space-empty-section ').css('marginTop').replace(/auto/, 0)
//         );

//     var oh = $('#sidebar').outerHeight();
//     var maxY = footTop - oh - 480;

//     window.addEventListener(
//         'scroll',
//         function () {
//             var y = window.scrollY;
//             if (y > top) {
//                 if (y < maxY) {
//                     $('#sidebar').addClass('fixed').removeAttr('style');
//                 } else {
//                     $('#sidebar')
//                         .removeClass('fixed')
//                         .css({
//                             position: 'absolute',
//                             top: maxY - top + 180 + 'px',
//                         });
//                 }
//             } else {
//                 $('#sidebar').removeClass('fixed');
//             }
//         },
//         { passive: true }
//     );
// });
let floatingSidebarHandler = floatingSidebar();
function floatingSidebar() {
    let spaceSidebarAnimation;

    const headerBounds = document
        .querySelector('header.header-inner-pages')
        .getBoundingClientRect();
    const sidebar = document.getElementById('sidebar');
    const initialSidebarBounds = sidebar.getBoundingClientRect();

    const headerHeight = headerBounds.height;
    const top =
        initialSidebarBounds.top + window.pageYOffset - headerHeight + 70; // was: headerHeight - 15
    let absoluteSet = false;
    let fixSet = false;

    function maxY() {
        const footerBounds = document
            .querySelector('.space-empty-section')
            .getBoundingClientRect();
        const footTop = footerBounds.top + window.pageYOffset;
        return footTop - initialSidebarBounds.height - headerHeight - 15;
    }

    return function () {
        if (spaceSidebarAnimation != null)
            cancelAnimationFrame(spaceSidebarAnimation);

        spaceSidebarAnimation = requestAnimationFrame(() => {
            const y = window.pageYOffset;
            if (y > top) {
                if (y >= maxY()) {
                    if (absoluteSet) return;
                    fixSet = false;
                    absoluteSet = true;
                    sidebar.style.cssText = 'top: auto; bottom: 0; position: absolute;'
                    return;
                } else {
                    absoluteSet = false;
                }
                if (fixSet) return;

                sidebar.classList.add('fixed');
                sidebar.style.cssText = '';
                fixSet = true;
            } else if (fixSet) {
                fixSet = false;
                sidebar.classList.remove('fixed');
            }
        });
    };
}
window.addEventListener('scroll', floatingSidebarHandler, { passive: true });
window.addEventListener(
    'resize',
    () => {
        floatingSidebarHandler = floatingSidebar();
        floatingSidebarHandler();
    },
    { passive: true }
);
floatingSidebarHandler();

const sectionMenuLinks = [
    ...document.querySelectorAll('.space-sub-navbar-main li a'),
];
const hrefToMenuTarget = {};
const hrefToLink = {};
let lastMenuSelected = location.hash || '#overview';
let menuSelected = location.hash || '#overview';
let scrollAnimation;

function highlightLink(href) {
    sectionMenuLinks.forEach((link) =>
        link.classList.remove('space-navbar-active')
    );
    const link = hrefToLink[href];
    if (link == null) return;
    link.classList.add('space-navbar-active');
    const navbar = document.getElementById('space-navbar');
    if (
        link.scrollIntoView != null &&
        navbar.classList.contains('space-fixed')
    ) {
        link.scrollIntoView();
    }
}

sectionMenuLinks.forEach(function (link) {
    const href = link.getAttribute('href');
    const target = document.querySelector(href);
    hrefToMenuTarget[href] = target;
    hrefToLink[href] = link;
});
function handleScroll() {
    if (scrollAnimation != null) cancelAnimationFrame(scrollAnimation);
    scrollAnimation = requestAnimationFrame(() => {
        const navbar = document.getElementById('space-navbar');
        const bounds = navbar.getBoundingClientRect();
        const boundsLast = document
            .querySelector(lastMenuSelected)
            .getBoundingClientRect();
        const navbarBottom = bounds.bottom + boundsLast.height / 4;
        lastMenuSelected = menuSelected;

        for (let link of sectionMenuLinks) {
            const linkHref = link.getAttribute('href');

            const target = hrefToMenuTarget[linkHref];
            const targetBounds = target.getBoundingClientRect();

            if (navbarBottom >= targetBounds.top) {
                menuSelected = linkHref;
            } else {
                break;
            }
        }
        if (lastMenuSelected !== menuSelected) highlightLink(menuSelected);
    });
}
document.addEventListener('scroll', handleScroll, { passive: true });
highlightLink(menuSelected);
handleScroll();

requestAnimationFrame(() => {
    loadSearchAutocompleteMenu('search_bar');
    loadSearchAutocompleteMenu('mobile_search_bar');
});
window.addEventListener(
    'scroll',
    () => {
        const googleMap = document.querySelector('.space-map-con iframe');
        if (!googleMap) return;

        googleMap.src = googleMap.dataset['src'];
    },
    { passive: true, once: true }
);

function setFakeSelectByValue(selectId, valueId, value) {

    const enquiryTypeOption = document.querySelector(`#${selectId} option[value="${value}"]`);
    if (enquiryTypeOption) {
      document.querySelector(`#${selectId} .select-styled`).innerText = enquiryTypeOption.innerText;
      document.getElementById(`${valueId}`).value = value;
    }
}

function openBookingEnquiryWithData(evt) {
  evt.preventDefault();
  const {target} = evt;
  const {enquiry, enquiryType} = target.dataset;
  if (enquiry) {
    const x = JSON.parse(enquiry);
    setFakeSelectByValue('enquiry_resource_type_code_select', 'enquiry_resource_type_code', enquiryType);
    console.log(x)
    if (x.capacity) setFakeSelectByValue('enquiry_capacity_select', 'enquiry_capacity', x.capacity);
    if (x.duration_qty) setFakeSelectByValue('enquiry_duration_qty_select', 'enquiry_duration_qty', x.duration_qty);
    if (x.duration_metric) setFakeSelectByValue('enquiry_duration_metric_select', 'enquiry_duration_metric', x.duration_metric);
  }
  $('#booking-enquiry-form-popup').modal('show');
}

document.querySelectorAll('a[data-enquiry]').forEach(a => {
  a.onclick = openBookingEnquiryWithData;
});
