import {
    getIdPrefix,
    validateEmail,
    validateEnglishName,
    sendLeadBooking,
} from '../../_shared/popups/lead_popup_base';
import intlTelInput from '../../_shared/vendor/intl-tel-input';

$(() => {
    setupEnquiryForm();
});

let phoneInput;
async function setupEnquiryForm() {
    //Cookies.set('resource_type_code', 'value');
    // var userFirstName = Cookies.get('userFirstName');
    // var userLastName = Cookies.get('userLastName');
    // var userEmail = Cookies.get('user_email');
    // var userCompany = Cookies.get('user_company');
    // var userPhone = Cookies.get('user_phone');
    // var userStartDate = Cookies.get('user_start_date');
    // var resourceTypeCode = Cookies.get('resource_type_code');
    // var userCapacity = Cookies.get('user_capacity');
    // var durationQty = Cookies.get('duration_qty');
    // var durationMetric = Cookies.get('duration_metric');
    // var userMessage = Cookies.get('user_message');
    var resourceTypeCode;

    // let form = $('#resource_availability_form');
    let form = $('#enquiry_submit_button').closest('form')[0];

    if (!form.id) {
        alert(
            'An error took place while sending your request. Please contact support at hello@coworker.com.'
        );
        return;
    }

    // pre set form values
    $('.modal').on('show.bs.modal', function (event) {
        let phone = $('#enquiry_phone'),
            lPadd = phone.prev('.iti__flag-container').width() + 90;
        phone.css('padding-left', lPadd);
        resourceTypeCode = $(event.relatedTarget).data('resource-type-code');
        if (resourceTypeCode) {
            $(
                `#enquiry_resource_type_code_select select option[value=${resourceTypeCode}]`
            ).attr('selected', 'selected');
        }
    });

    // hide validation errors on close
    $('.modal').on('hidden.bs.modal', function () {
        $(this).find('.validation-error').hide();
    });

    // handle bootstrap dropdown menus to be able to collects selected values
    $('#enquiry_resource_type_code_select li').on('click', function () {
        $('#enquiry_resource_type_code').val($(this).attr('rel'));
    });
    $('#enquiry_duration_metric_select li').on('click', function () {
        $('#enquiry_duration_metric').val($(this).attr('rel'));
    });
    $('#enquiry_duration_qty_select li').on('click', function () {
        $('#enquiry_duration_qty').val($(this).attr('rel'));
    });
    $('#enquiry_capacity_select li').on('click', function () {
        $('#enquiry_capacity').val($(this).attr('rel'));
    });
    $('#enquiry_time_slot_select li').on('click', function () {
        $('#enquiry_time_slot').val($(this).attr('rel'));
    });

    let idPrefix = getIdPrefix(form);
    var input = document.querySelector('#enquiry_phone');
    phoneInput = await intlTelInput(input, {
        allowDropdown: true,
        separateDialCode: true,
        autoHideDialCode: false,
        autoPlaceholder: 'off',
        dropdownContainer: document.body,
        excludeCountries: [''],
        formatOnDisplay: true,
        geoIpLookup: function (callback) {
            $.get('https://ipinfo.io', function () {}, 'jsonp').always(
                function (resp) {
                    let countryCode = resp && resp.country ? resp.country : '';
                    let city = resp && resp.city ? resp.city : '';
                    let region = resp && resp.region ? resp.region : '';
                    let ip = resp && resp.ip ? resp.ip : '';
                    let latLng = resp && resp.loc ? resp.loc : '';
                    var latLngParts = latLng.split(',');
                    $(idPrefix + '_user_country_code').val(countryCode);
                    $(idPrefix + '_user_city').val(city);
                    $(idPrefix + '_user_region').val(region);
                    $(idPrefix + '_user_ip').val(ip);
                    $(idPrefix + '_user_lat').val(latLngParts[0]);
                    $(idPrefix + '_user_lng').val(latLngParts[1]);
                    callback(countryCode);
                }
            );
        },
        hiddenInput: 'enquiry_full_number',
        initialCountry: 'auto',
        localizedCountries: {},
        nationalMode: false,
        onlyCountries: [],
        placeholderNumberType: 'MOBILE',
        preferredCountries: [],
    });

    // setup submit button
    $(form).on('submit', function (e) {
        e.preventDefault();
        $(form).find('.validation-error').hide();
        let submitButton = $(form).find(':submit');
        submitButton.attr('disabled', true);
        if (validateEnquiryForm(form)) {
            let ldsRing = $(form).find('.lds-ring')[0];
            ldsRing.style.display = 'inline-block';
            submitButton.attr('original-value', submitButton.attr('value'));
            submitButton.attr('value', 'Processing...');
            let params = getEnquiryLeadFormData(form);
            sendLeadBooking(form, params);
        } else {
            submitButton.attr('value', submitButton.attr('original-value'));
            submitButton.attr('disabled', false);
        }
    });
}

function getEnquiryLeadFormData(form) {
    let idPrefix = getIdPrefix(form);
    let phone = phoneInput.getNumber(intlTelInputUtils.numberFormat.E164);
    let phoneCountryCode = phoneInput.getSelectedCountryData().iso2;

    return {
        lead_type_code: $(idPrefix + '_lead_type_code').val(),
        capture_page: $(idPrefix + '_capture_page').val(),
        space_id: $(idPrefix + '_space_id').val(),
        user_id: $(idPrefix + '_user_id').val(),
        user_first_name: $(idPrefix + '_first_name').val(),
        user_last_name: $(idPrefix + '_last_name').val(),
        user_last_name_: $(idPrefix + '_last_name_').val(),
        user_name:
            $(idPrefix + '_first_name').val() +
            ' ' +
            $(idPrefix + '_last_name_').val(),
        user_email: $(idPrefix + '_email').val(),
        user_phone: phone,
        user_phone_country_code: phoneCountryCode,
        user_company: $(idPrefix + '_company_name').val(),
        user_message: JSON.stringify($(idPrefix + '_message').val()),
        user_country_code: $(idPrefix + '_user_country_code').val(),
        user_city: $(idPrefix + '_user_city').val(),
        user_region: $(idPrefix + '_user_region').val(),
        user_ip: $(idPrefix + '_user_ip').val(),
        user_lat: $(idPrefix + '_user_lat').val(),
        user_lng: $(idPrefix + '_user_lng').val(),
        start_date: $(idPrefix + '_start_date').val(),
        resource_type_code: $(idPrefix + '_resource_type_code').val(),
        duration_metric: $(idPrefix + '_duration_metric').val(),
        duration_qty: $(idPrefix + '_duration_qty').val(),
        capacity: $(idPrefix + '_capacity').val(),
    };
}

function validateEnquiryForm(form) {
    let hasErrors = false;
    let idPrefix = getIdPrefix(form);
    const $form = $(form);

    $form
        .find('input, select, textarea')
        .filter('[is_required]')
        .each(function (i, requiredField) {
            if ($.trim($(requiredField).val()) === '') {
                $(requiredField).siblings('.validation-error').show();
                hasErrors = true;
            }
        });

    let emailInput = $(idPrefix + '_email');
    if (emailInput.length > 0 && !validateEmail(emailInput.val())) {
        hasErrors = true;
        $(emailInput).siblings('.validation-error').show();
    }

    if (Math.abs(phoneInput.getValidationError()) > 0) {
        hasErrors = true;
        $('#enquiry_phone_error.validation-error').show();
    }

    const dateText = $('#date_today').val();
    const todayDate = dateText ? $.datepicker.parseDate('mm/dd/yy', dateText) : new Date();
    $form.find('.datepicker').each(function (i, dateField) {
        const $field = $(dateField);
        const dateText = $.trim($field.val());
        let enteredDate = null;
        try {
            enteredDate = $.datepicker.parseDate('mm/dd/yy', dateText);
        } catch (_) {
        }
        if (enteredDate == null || enteredDate < todayDate) {
            const $errors = $(dateField).siblings('.validation-error');
            $errors.text('Invalid date');
            $errors.show();
            hasErrors = true;
        }
    });

    if (!hasErrors) {
        return true;
    } else {
        return false;
    }
}
