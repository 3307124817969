<script lang="ts">
  import IMAGE_BUY_DAYPASS from '../Common/img/img_space_buydaypass.jpg?url';
  import IMAGE_CALENDAR from '../../_shared/common/img/img_calendar_icon.png?url';
  import IMAGE_ARROW from '../Common/img/img_buydaypass_arrow.svg?url';

  import { format as formatDate, addBusinessDays } from 'date-fns';

  import type { HydrateJSON, ResourceBooking, SearchResponse } from './types';

  export let hydrate: HydrateJSON;
  export let resource: ResourceBooking;
  export let searchResponse: SearchResponse;

  export let startDate: Date = new Date();
  export let numDays: number = 1;

  export let startTime: string = '';
  export let endTime: string = '';

  function weekday(date: Date) {
    return formatDate(date, 'eee MMM d, y');
  }

  let startDateFormatted: string;
  let endDateFormatted: string;
  $: startDateFormatted = weekday(startDate);
  $: endDateFormatted = weekday(addBusinessDays(startDate, numDays));

  function usd(amount: number) {
    const converted = amount;
    return `${converted.toFixed(2)} USD`;
  }

  function roundCurrency(amount: number, iwg_currency: string) {
    const converted = amount;
    return `${converted.toFixed(2)} ${iwg_currency}`;
  }
</script>

<div class="post-requirment-right-outer">
  <div class="buydaypass-space-con">
    <div class="buydaypass-left-con">
      <img loading="lazy" src={IMAGE_BUY_DAYPASS} alt="pic" />
    </div>
    <div class="buydaypass-right-con">
      <h3>{hydrate.spaceName}</h3>
      <div class="placeholderText">{resource.mrName}</div>
      <div class="buydaypass-capacity"><p>{resource.mrCapacity}</p></div>
      <div class="buydaypass-reviews-con">
        {#each { length: 5 } as _}
          <i class="fa fa-star" />
        {/each}
        <span>{hydrate.totalReviewCount} Reviews</span>
      </div>
    </div>
  </div>

  <div class="buydaypass-price-outer">
    <div class="buydaypass-time-con">
      <img src={IMAGE_CALENDAR} alt="calendar" />
      <span>{startDateFormatted}</span>
    </div>
    <div class="buydaypass-time-con startEndTime">
      <img src={IMAGE_CALENDAR} alt="calendar" />
      <span>Start Time {startTime}</span>
      <img src={IMAGE_ARROW} alt="arrow" class="buydaypass-arrow" />
      <span>End Time {endTime}</span>
    </div>
    <div class="buydaypass-price-panel">
      <div class="buydaypass-price-con">
          <div class="buydaypass-price-left">Meeting Room - {resource.mrCapacity}</div>
          <div class="buydaypass-price-right">{ searchResponse.step === 1 ? roundCurrency(searchResponse.sub_total, searchResponse.currency) : usd(searchResponse.sub_total_usd)}</div>
      </div>
    </div>
    <div class="buydaypass-deposit-outer">
      <div class="buydaypass-price-con">
          <div class="buydaypass-price-left">Subtotal</div>
          <div class="buydaypass-price-right">{searchResponse.step === 1 ? roundCurrency(searchResponse.sub_total, searchResponse.currency) : usd(searchResponse.sub_total_usd)}</div>
      </div>
    </div>
    <div class="buydaypass-deposit-outer">
      <div class="buydaypass-price-con">
          <div class="buydaypass-price-left">Tax</div>
          <div class="buydaypass-price-right">{searchResponse.step === 1 ? roundCurrency(searchResponse.tax, searchResponse.currency) : usd(searchResponse.tax_usd)}</div>
      </div>
    </div>

    <div class="buydaypass-total-outer">
      <div class="buydaypass-price-con buydaypass-payable-now">
        <div class="buydaypass-price-left">Total</div>
        <div class="buydaypass-price-right">
          {searchResponse.step === 1 ? roundCurrency(searchResponse.total, searchResponse.currency) : usd(searchResponse.total_usd)}
        </div>
      </div>
    </div>
  </div>

  <div class="buydaypass-terms-con">
    <p>This payment will reserve your Meeting Room booking. You may cancel your booking up until 24 hours before your reservation date. Bookings cancelled within 24 hours of the reservation are non refundable.</p>
    {#if searchResponse.step == 2}
    <p>All prices are in US dollars.</p>
    {/if}
  </div>
</div>
