<script lang="ts" context="module">
  declare global {
    interface Window {
      grecaptcha: any;
      dataLayer: any;
    }
  }

  const emailRegex = /\S+@\S+\.\S+/;
</script>

<script lang="ts">
  import IMAGE_CROSS from '../_shared/common/img/img_cross.png?url';

  import ResourceSelect from './ResourceSelect.svelte';

  import Invalid from './Common/ValidationError.svelte';
  import Total from './BookingService/Total.svelte';
  import FormStep2 from './BookingService/FormStep2.svelte';

  import { fetchGeoIP } from '../_shared/fetch-geoip';
  import type { GeoIPResponse } from '../_shared/fetch-geoip';

  import type {
    HydrateJSON,
    ResourceBooking,
    FormFieldsStep1,
    FormData,
    Response,
    SearchData,
    SearchResponse,
  } from './BookingService/types';
  import type { Plugin as IntlTelInput } from 'intl-tel-input';

  import { onDestroy, onMount } from 'svelte';
  // import Select from 'svelte-select';
  import DatePicker from 'svelte-flatpickr/src/Flatpickr.svelte';
  import '../_shared/flatpickr-theme.css';
  import intlTelInput from '../_shared/vendor/intl-tel-input';

  export let hydrate: HydrateJSON;
  export let resource: ResourceBooking;
  export let onClose: () => void;
  export let onReady: (modal: HTMLDivElement) => void;
  export let onSuccess: (response: Response) => void;
  export let onError: (response?: Response) => void;

  let modal: HTMLDivElement;

  let geoip: GeoIPResponse | null;
  let phoneInput: IntlTelInput;

  let progress;
  let step2;

  let timesMap = {
    '09:00 AM' : 9,
    '10:00 AM' : 10,
    '11:00 AM' : 11,
    '12:00 PM' : 12,
    '01:00 PM' : 13,
    '02:00 PM' : 14,
    '03:00 PM' : 15,
    '04:00 PM' : 16,
    '05:00 PM' : 17
  }

  function isTimeValid( time, startTime ) {
    if ( typeof( startTime ) !== 'undefined' ) {
      let startValue = timesMap[ startTime ];
      let endValue = timesMap[ time ];
      if ( endValue > startValue ) {
        return false;
      }
    }
    return true;
  }

  onMount(async () => {
    geoip = await fetchGeoIP();
    phoneInput = await intlTelInput(form.phone, {
      allowDropdown: true,
      separateDialCode: true,
      autoHideDialCode: false,
      autoPlaceholder: 'off',
      dropdownContainer: document.body,
      excludeCountries: [''],
      formatOnDisplay: true,
      geoIpLookup: async (cb: (countryCode: any) => void) => {
        if (!geoip) {
          geoip = await fetchGeoIP();
        }
        cb(geoip?.country);
      },
      initialCountry: 'auto',
      localizedCountries: {},
      nationalMode: false,
      onlyCountries: [],
      placeholderNumberType: 'MOBILE',
      preferredCountries: [],
    });
    onReady(modal);
  });

  onDestroy(() => {
    phoneInput.destroy();
  });

  let step: 0 | 1 | 2 = 0;

  type FormField = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
  let form: Partial<Record<FormFieldsStep1, FormField>> = {};

  let value: Partial<Record<FormFieldsStep1, string>> = {};

  let startDate: Date | undefined;
  function handleStartDateChange(event: CustomEvent<[Date[], string]>) {
    const dates = event.detail[0];
    startDate = dates[0];
  }

  let booking_context: string;
  $: booking_context = '';

  let startTime: string | undefined;
  let endTime: string | undefined;

  $: startTime = value.startTime;
  $: endTime = value.endTime;

  let invalid: Record<FormFieldsStep1, boolean> = {
    firstName: false,
    lastName: false,
    companyName: false,
    companyEmail: false,
    phone: false,
    capacity: false,
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
    message: false,
  };

  let searchResponse: SearchResponse = {
    step: 0,
    total: 0,
    tax: 0,
    sub_total: 0,
    discount: 0,
    currency: '',
    total_usd: 0,
    tax_usd: 0,
    sub_total_usd: 0,
    discount_usd: 0
  };

  let isSubmitting = false;
  // flatpickr and svelte-flatpickr do not directly support disabling, so the input field must be manually disabled
  $: if (form.startDate) {
    form.startDate.disabled = isSubmitting;
  }

  /**
   * @returns true if valid, false otherwise
   */
  function validateForm(): boolean {
    let isInvalid = false;
    const entries = Object.entries(form) as Array<[FormFieldsStep1, FormField]>;
    entries.forEach(([field, input]) => {
      value[field] = input.value.trim();
      if (input.dataset.required != null) {
        const empty = !value[field];
        isInvalid ||= empty;
        invalid[field] = empty;
      }
    });
    invalid.companyEmail = value.companyEmail == null || !emailRegex.test(value.companyEmail);
    isInvalid ||= invalid.companyEmail;
    invalid.phone = !phoneInput.isValidNumber();
    isInvalid ||= invalid.phone;

    if (!isInvalid) {
      const current_date = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      let fromStartTime = new Date(Date.parse(current_date + " " + value.startTime));
      let toEndTime = new Date(Date.parse(current_date + " " + value.endTime));
      invalid.endTime = fromStartTime >= toEndTime
      isInvalid ||= invalid.endTime
    }

    return !isInvalid;
  }

  function validateBookingService(callback: Function): void {
    progress.style.display = 'inline-block'
    isSubmitting = true;

    // At this stage, validation for required fields has been performed
    const values = value as Required<typeof value>;

    const data: SearchData = {

      space_id: hydrate.spaceId,
      service_type: 'iwg',
      service_type_code: 'meeting_room',

      capacity: values.capacity,
      start_date: values.startDate,
      end_date: '',
      start_time: values.startTime,
      end_time: values.endTime

    };

    window.grecaptcha.ready(async () => {
      const captchaToken = await window.grecaptcha.execute(
              '6LcaVKAUAAAAAAfDTPgFv8tGuwrodh_Mv6_eJ0oT',
              {
                action: 'handleIwgBookingSearchResponse',
              }
      );
      try {
        const response = await fetch('/ajax/iwg-booking-service/search', {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
            captcha_token: captchaToken,
          }),
        });
        const json = await response.json();
        if (response.status === 201) {
          searchResponse.sub_total = json.data.sub_total;
          searchResponse.tax = json.data.tax;
          searchResponse.total = json.data.total;
          searchResponse.currency = json.data.currency;
          searchResponse.sub_total_usd = json.data.sub_total_usd;
          searchResponse.tax_usd = json.data.tax_usd;
          searchResponse.total_usd = json.data.total_usd;
          searchResponse.duration_metric = json.data.duration_metric;
          searchResponse.duration_qty = json.data.duration_qty;
          booking_context = json.data.ctx;

          isSubmitting = false;
          window.dataLayer?.push({ event: 'buy_day_pass' });
          callback();
          progress.style.display = 'none'
        } else {
          isSubmitting = false;
          onError(json);
          progress.style.display = 'none'
        }
      } catch (err) {
        isSubmitting = false;
        onError();
        progress.style.display = 'none'
      } finally {
      }
    });
  }
  function nextStep(): void {
    step = 1;
    searchResponse.step = 1;
  }

  function handleContinue(): void {
    if (validateForm()) {
      if (step == 0) {
        validateBookingService(nextStep);
      } else {
        step = 2;
        searchResponse.step = 2;
      }
    }
  }

  function handleBack(): void {
    step = 1;
  }

  function onBackToFirstStep(): void {
    step = 0;
  }

  function handleSubmit(stripeTokenId: string): void {
    isSubmitting = true;

    // At this stage, validation for required fields has been performed
    const values = value as Required<typeof value>;

    const data: FormData = {
      capture_page: hydrate.capturePageName,
      service_type: 'iwg',
      service_type_code: 'meeting_room',
      lead_type_code: 'meeting_room',
      resource_type_code: 'meeting_room',
      space_id: hydrate.spaceId,

      user_name: values.firstName + ' ' + values.lastName,
      user_first_name: values.firstName,
      user_last_name: values.lastName, // always set to be empty since there is no field
      user_email: values.companyEmail,
      user_company: values.companyName,
      user_phone: phoneInput.getNumber(),
      user_phone_country_code: phoneInput.getSelectedCountryData().iso2,

      capacity: values.capacity,
      duration_metric: searchResponse.duration_metric,
      duration_qty: searchResponse.duration_qty,
      start_date: values.startDate,
      start_time: values.startTime,
      end_time: values.endTime,
      time_slot: values.startTime + ' - ' + values.endTime,
      user_message: values.message,

      user_country_code: geoip?.country,
      user_city: geoip?.city,
      user_ip: geoip?.ip,
      user_lat: geoip?.latitude,
      user_lng: geoip?.longitude,
      user_region: geoip?.region,

      user_id: hydrate.userId,

      stripe_token: stripeTokenId,

      booking_ctx: booking_context,
      subt_total: searchResponse.sub_total,
      tax: searchResponse.tax,
      total: searchResponse.total,
      currency: searchResponse.currency,
      sub_total_usd: searchResponse.sub_total_usd,
      tax_usd: searchResponse.tax_usd,
      total_usd: searchResponse.total_usd,
    };

    window.grecaptcha.ready(async () => {
      const captchaToken = await window.grecaptcha.execute(
              '6LcaVKAUAAAAAAfDTPgFv8tGuwrodh_Mv6_eJ0oT',
              {
                action: 'handleStripeTokenResponse',
              }
      );
      try {
        progress.style.display = 'inline-block'
        const response = await fetch('/ajax/iwg-booking-service/create', {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
            captcha_token: captchaToken,
          }),
        });
        const json = await response.json();
        if (response.status === 201) {
          onSuccess(json.data);
          window.dataLayer?.push({ event: 'buy_day_pass' });
        } else {
          onError(json);
        }
      } catch (err) {
        onError();
      } finally {
        step2.hideProgress();
        isSubmitting = false;
        progress.style.display = 'none';
      }
    });
  }
</script>

<div
        class="modal fade post-your-requirment-popup-outer buydaypass-popup-outer"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        bind:this={modal}
        on:click|preventDefault={onClose}
>
  <div class="modal-dialog modal-dialog-centered {step === 0 ? 'modal-dialog-step' : '' }" role="document" on:click|stopPropagation>
    <div class="modal-content">
      <div class="modal-header">
        <button
                type="button"
                class="close"
                aria-label="Close"
                on:click|preventDefault={onClose}
        >
          <span aria-hidden="true">
            <img src={IMAGE_CROSS} alt="cross icon" />
          </span>
        </button>
      </div>

      <div class="post-requirment-inner">
        <form
                on:submit|preventDefault={handleContinue}
                style={(step === 0 || step === 1) ? '' : 'display:none' }
        >
          <div class="post-requirment-left-outer {step === 0 ? 'post-requirment-left-outer-first-step' : '' }">
            <h2>Complete Your Meeting Room Booking</h2>
            <div class="col-12 pad-none post-requirment-field-outer">
              <div class="post-requirment-left">
                <input
                        bind:this={form.firstName}
                        disabled={isSubmitting}
                        placeholder="First Name"
                        type="text"
                        data-required
                />
                <Invalid show={invalid.firstName}
                >First name is required</Invalid
                >
              </div>
              <div class="post-requirment-right">
                <input
                        bind:this={form.lastName}
                        disabled={isSubmitting}
                        placeholder="Last Name"
                        type="text"
                        data-required
                />
                <Invalid show={invalid.lastName}>Last name is required</Invalid>
              </div>
            </div>

            <div class="col-12 pad-none post-requirment-field-outer">
              <div class="post-requirment-left">
                <input
                        bind:this={form.companyName}
                        disabled={isSubmitting}
                        placeholder="Company Name"
                        type="text"
                        data-required
                />
                <Invalid show={invalid.companyName} />
              </div>
              <div class="post-requirment-right">
                <input
                        bind:this={form.companyEmail}
                        disabled={isSubmitting}
                        placeholder="Company Email"
                        type="text"
                        data-required
                />
                <Invalid show={invalid.companyEmail}
                >Valid email is required</Invalid
                >
              </div>
            </div>

            <div class="col-12 pad-none post-searching-space-outer ">
              <div class="col-12 pad-none post-requirment-field-outer">
                <div class="post-requirment-left">
                  <input
                          bind:this={form.phone}
                          type="tel"
                          placeholder="Phone Number"
                          data-required
                  />
                  <Invalid show={invalid.phone}
                  >Valid phone number is required</Invalid
                  >
                </div>
                <div class="post-requirment-right">
                  <input
                          type="text"
                          value="Meeting Rooms"
                          readonly
                  />
                </div>
              </div>

              <div class="col-12 pad-none post-searching-space-outer ">
                <div class="col-12 pad-none post-requirment-field-outer">
                  <div class="post-requirment-left">
                    <input
                            bind:this={form.capacity}
                            bind:value={value.capacity}
                            disabled={ step !=0 }
                            type="number"
                            min="1"
                            max="500"
                            placeholder="Number of Guests"
                            data-required
                    />
                    <Invalid show={invalid.capacity} />
                  </div>
                  <div class="post-requirment-right">
                    <DatePicker
                            bind:input={form.startDate}
                            on:change={handleStartDateChange}
                            disabled={ step !=0 }
                            type="text"
                            placeholder="Start Date"
                            class="home-duration-input"
                            options={{
                        minDate: 'today',
                        disableMobile: true,
                      }}
                            data-required
                    />
                    <Invalid show={invalid.startDate} />
                  </div>
                </div>
              </div>

              <div class="col-12 pad-none post-searching-space-outer ">
                <div class="col-12 pad-none post-requirment-field-outer">
                  <div class="post-requirment-left">
                    <div class="select-override">
                      <select
                              bind:this={form.startTime}
                              bind:value={value.startTime}
                              disabled={ step !=0 }
                              style="height: 46px;{ value.startTime === '' ? '' : 'color:#3f4f5f;' }"
                              data-required
                      >
                        <option value="">Start Time</option>
                        <option value="09:00 AM">09:00 AM</option>
                        <option value="10:00 AM">10:00 AM</option>
                        <option value="11:00 AM">11:00 AM</option>
                        <option value="12:00 PM">12:00 PM</option>
                        <option value="01:00 PM">01:00 PM</option>
                        <option value="02:00 PM">02:00 PM</option>
                        <option value="03:00 PM">03:00 PM</option>
                        <option value="04:00 PM">04:00 PM</option>
                        <option value="05:00 PM">05:00 PM</option>
                      </select>
                      <Invalid show={invalid.startTime} />
                    </div>
                  </div>
                  <div class="post-requirment-right">
                    <div class="select-override">
                      <select
                              bind:this={form.endTime}
                              bind:value={value.endTime}
                              disabled={ step !=0 || value.startTime == '' }
                              style="height: 46px;{ value.endTime === '' ? '' : 'color:#3f4f5f;' }"
                              data-required
                      >
                        <option value="">End Time</option>
                        <option disabled="{isTimeValid('09:00 AM', value.startTime)}" value="09:00 AM">09:00 AM</option>
                        <option disabled="{isTimeValid('10:00 AM', value.startTime)}" value="10:00 AM">10:00 AM</option>
                        <option disabled="{isTimeValid('11:00 AM', value.startTime)}" value="11:00 AM">11:00 AM</option>
                        <option disabled="{isTimeValid('12:00 PM', value.startTime)}" value="12:00 PM">12:00 PM</option>
                        <option disabled="{isTimeValid('01:00 PM', value.startTime)}" value="01:00 PM">01:00 PM</option>
                        <option disabled="{isTimeValid('02:00 PM', value.startTime)}" value="02:00 PM">02:00 PM</option>
                        <option disabled="{isTimeValid('03:00 PM', value.startTime)}" value="03:00 PM">03:00 PM</option>
                        <option disabled="{isTimeValid('04:00 PM', value.startTime)}" value="04:00 PM">04:00 PM</option>
                        <option disabled="{isTimeValid('05:00 PM', value.startTime)}" value="05:00 PM">05:00 PM</option>
                      </select>
                      <Invalid show={invalid.endTime} />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 pad-none post-requirment-field-outer">
                <textarea
                        bind:this={form.message}
                        placeholder="Message or additional requirements"
                        disabled={isSubmitting}
                />
                <Invalid show={invalid.message}>Message is required</Invalid>
              </div>

              <div class="col-12 pad-none post-requirment-field-outer complete-day-purchase-button">
                {#if step === 1}
                  <a href="#" class="complete-back-button" on:click|preventDefault={onBackToFirstStep}>Back</a>
                {/if}
                <input type="submit" value="Continue" disabled={isSubmitting} />
                <div bind:this={progress} style="width: 32px; height: 32px;" class="lds-ring"><div></div><div></div><div></div><div></div></div>
                <span
                >You will not be charged until you confirm this booking on the
                  next screen</span
                >
              </div>
            </div>
          </div>
          {#if step === 1}
            <Total {hydrate} {resource} {searchResponse} {startTime} {endTime} {startDate} />
          {/if}
        </form>
        <FormStep2
                bind:this={step2}
                {isSubmitting}
                onSubmit={handleSubmit}
                onBack={handleBack}
                show={step === 2}
        >
          <Total {hydrate} {resource} {searchResponse} {startTime} {endTime} {startDate} />
        </FormStep2>
      </div>
    </div>
  </div>
</div>

<style>

  .select-override {
    --border: 1px solid #cad2d0;
    --borderRadius: 2px;
    --height: 46px;
    --inputFontSize: 13px;
    font-size: 13px;
    --inputColor: inherit;
    --placeholderColor: #aab3b1;
    --borderFocusColor: #00c78a !important;
    --borderHoverColor: #cad2d0;
    --itemHoverBG: #edf4f1;
    --itemIsActiveBG: #00927c;
  }

  .select-override :global(input:hover) {
    background: transparent !important;
  }

  .select-override :global(.selectContainer.focused) {
    box-shadow: 0 0 0 2px #dfede7 !important;
  }
  .select-override :global(.selectContainer input) {
    box-shadow: none !important;
  }

  .modal :global(.iti) {
    font-size: 13px;
  }

  .modal :global(.iti--allow-dropdown input[type='tel']) {
    padding-left: 58px;
  }

  .modal-content {
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.26);
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .modal-header .close img {
    filter: brightness(0.4) drop-shadow(0 4px 13px rgba(0, 0, 0, 0.26));
  }
</style>
