<script lang="ts">
    import Select from 'svelte-select';
    import {countries} from './CountrySelect/countries';
    
    export let disabled = false;
    export let value: string | undefined;
    
    let select: {value: string} | undefined;
    
    $: value = select?.value;

</script>

<style>
.select-override {
--border: 1px solid #cad2d0;
--borderRadius: 2px;
--height: 46px;
--inputFontSize: 13px;
font-size: 13px;
--inputColor: inherit;
--placeholderColor: #AAB3B1;
--borderFocusColor: #00c78a !important;
--borderHoverColor: #cad2d0;
--itemHoverBG: #edf4f1;
--itemIsActiveBG: #00927c;
}

.select-override :global(input:hover) {
background: transparent !important;
}

.select-override :global(.selectContainer.focused) {
box-shadow: 0 0 0 2px #dfede7 !important;
}
.select-override :global(.selectContainer input) {
box-shadow: none !important;
}
</style>

<div class="select-override">
<Select
    items={countries}
    placeholder="Country"
    bind:selectedValue={select}
    isDisabled={disabled}
/>
</div>