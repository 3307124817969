<script lang="ts">
  import IMAGE_USER_ICON from '../../_shared/common/img/img_user_icon.png?url';
  import IMAGE_BUY_DAYPASS from '../Common/img/img_space_buydaypass.jpg?url';
  import IMAGE_CALENDAR from '../../_shared/common/img/img_calendar_icon.png?url';
  import IMAGE_ARROW from '../Common/img/img_buydaypass_arrow.svg?url';

  import { format as formatDate, addBusinessDays } from 'date-fns';

  import type { HydrateJSON } from './types';
  export let hydrate: HydrateJSON;

  export let total: number = 0;
  export let startDate: Date = new Date();
  export let numDays: number = 1;
  export let numGuests: number = 1;

  function weekday(date: Date) {
    return formatDate(date, 'eee MMM d, y');
  }

  let startDateFormatted: string;
  let endDateFormatted: string;
  $: startDateFormatted = weekday(startDate);
  $: endDateFormatted = weekday(addBusinessDays(startDate, numDays));

  $: total = hydrate.globalPassPrice;

  function usd(amount: number) {
    const converted = amount;
    return `${converted.toFixed(2)} USD`;
  }
</script>

<div class="post-requirment-right-outer">
  <div class="buydaypass-space-con">
    <div class="buydaypass-left-con">
      <img loading="lazy" src={IMAGE_BUY_DAYPASS} alt="pic" />
    </div>
    <div class="buydaypass-right-con">
      <h3>{hydrate.spaceName}</h3>
      <div class="buydaypass-reviews-con">
        {#each { length: 5 } as _}
          <i class="fa fa-star" />
        {/each}
        <span>{hydrate.totalReviewCount} Reviews</span>
      </div>
    </div>
  </div>

  <div class="buydaypass-price-outer">
    <div class="buydaypass-guest-con">
      <img src={IMAGE_USER_ICON} alt="pic" />
      <span>{numGuests} Guest</span>
    </div>
    <div class="buydaypass-time-con">
      <img src={IMAGE_CALENDAR} alt="calendar" />
      <span>{startDateFormatted}</span>
      <img src={IMAGE_ARROW} alt="arrow" class="buydaypass-arrow" />
      <span>{endDateFormatted}</span>
    </div>

    <div class="buydaypass-price-panel">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">
          Global Pass {numGuests} x {hydrate.globalPassPrice}
          USD x {numDays} Day
        </div>
        <div class="buydaypass-price-right">
          {usd(total)}
        </div>
      </div>
    </div>

    <div class="buydaypass-total-outer">
      <div class="buydaypass-price-con">
        <div class="buydaypass-price-left">Total</div>
        <div class="buydaypass-price-right">
          {usd(total)}
        </div>
      </div>
      <div class="buydaypass-price-con buydaypass-payable-now">
        <div class="buydaypass-price-left">&nbsp;</div>
        <div class="buydaypass-price-right">&nbsp;</div>
      </div>
    </div>
  </div>

  <div class="buydaypass-terms-con">
    <p>All prices are US dollars.</p>
  </div>
</div>
