<script lang="ts">
  import IMAGE_PAYMENT_1 from '../Common/img/img_payment_icons01.png?url';
  import IMAGE_PAYMENT_2 from '../Common/img/img_payment_icons02.png?url';
  import IMAGE_PAYMENT_3 from '../Common/img/img_payment_icons03.png?url';
  import IMAGE_PAYMENT_4 from '../Common/img/img_payment_icons04.png?url';
  import IMAGE_PAYMENT_5 from '../Common/img/img_payment_icons05.png?url';
  import IMAGE_PAYMENT_SSL from '../Common/img/img_payment_secure_ssl.png?url';

import Invalid from '../Common/ValidationError.svelte';
import StripeCardInput, { createToken } from '../StripeCardInput.svelte'
import type {StripeCardElement, StripeToken, StripeValidationError, StripeError} from '../StripeCardInput.svelte';
import CountrySelect from '../CountrySelect.svelte';

import type { FormFieldsStep2 } from "./types";

export let isSubmitting = false;
export let onSubmit: (stripeTokenId: string) => void;
export let onBack: () => void;
export let show = false;

type FormField = HTMLInputElement;
let form: Partial<Record<FormFieldsStep2, HTMLInputElement>> = {}
let cardElement: StripeCardElement;

let value: Partial<Record<FormFieldsStep2, string>> = {}
let stripeToken: StripeToken | undefined;

let invalid: Record<FormFieldsStep2, boolean> = {
  name: false,
  countryCode: false,
  stripeToken: false
}
let invalidCard: StripeValidationError | undefined;
let invalidToken: StripeError | undefined;
let completeCard = false;
let progress;

/**
 * @returns true if valid, false otherwise
 */
async function validateForm(): Promise<boolean> {
  let isInvalid = false;
  const entries = Object.entries(form) as Array<[FormFieldsStep2, FormField]>;
  entries.forEach(([field, input]) => {
    value[field] = input.value.trim();
    if (input?.dataset.required) {
      const empty = !value[field];
      isInvalid ||= empty;
      invalid[field] = empty;
    }
  });
  isInvalid ||= invalidCard != null || !completeCard;

  if (!isInvalid) {
    const result = await createToken(cardElement, {
      name: value.name,
      address_country: value.countryCode
    });

    if (result.error) {
      invalidToken = result.error;
      isInvalid = true;
    } else {
      stripeToken = result.token;
    }
  }

  return !isInvalid;
}

async function handleSubmit() {
  progress.style.display = 'inline-block'
  if (await validateForm()) {
    onSubmit(stripeToken!.id);
  } else {
    progress.style.display = 'none'
  }
}
</script>

<style>
</style>

<form on:submit|preventDefault={handleSubmit} style={show ? '' : 'display: none'}>
  <div class="post-requirment-left-outer">
    <h2>
      Complete your Booking
    </h2>
    <div class="col-12 pad-none post-requirment-field-outer">
      <input bind:this={form.name} placeholder="Cardholder Name" type="text" data-required>
      <Invalid show={invalid.name}>Cardholder name is required</Invalid>
    </div>

    <div class="col-12 pad-none post-requirment-field-outer">
      <CountrySelect bind:value={value.countryCode} />
      <Invalid show={invalid.countryCode}>Country is required</Invalid>
    </div>

    <div class="col-12 pad-none post-requirment-field-outer">
      <StripeCardInput bind:cardElement={cardElement} bind:invalid={invalidCard} bind:complete={completeCard} />
      <Invalid show={invalidCard != null}>{invalidCard?.message}</Invalid>
    </div>

    <div class="col-12 pad-none post-searching-space-outer ">
      <div class="col-12 pad-none post-requirment-field-outer" style="display:flex; align-items: center; justify-content: space-between;">
        <div class="post-requirment-left buydaypass-payment-icons" style="margin:0;">
          <img src={IMAGE_PAYMENT_1} alt="payment icon">
          <img src={IMAGE_PAYMENT_2} alt="payment icon">
          <img src={IMAGE_PAYMENT_3} alt="payment icon">
          <img src={IMAGE_PAYMENT_4} alt="payment icon">
          <img src={IMAGE_PAYMENT_5} alt="payment icon">
        </div>

        <div style="display:flex;align-items: center; height: 32px;font-weight:bold;border-radius: 3px; border: 1px solid #ddd;padding: 0 8px;font-size: 12px">
            <img src={IMAGE_PAYMENT_SSL} alt="SSL secure" style="padding-right:0.5rem">
            <span style="color:#464d4b; margin: 0;">SSL</span>&nbsp;<span style="color:#8fbe51; margin:0;">SECURE</span>
        </div>
      </div>


      <div class="col-12 pad-none post-requirment-field-outer complete-day-purchase-button">
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" class="complete-back-button" on:click|preventDefault={onBack}>Back</a>
        <input type="submit" value="Complete" disabled={isSubmitting}>
        <div bind:this={progress} class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>

    </div>

  </div>

<slot></slot>
</form>
