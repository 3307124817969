$('body').on('click', 'a.review-page-link', function (e) {
    e.preventDefault();
    let reviewPageNum = $(this).data("review-page-num");
    renderReviews(reviewPageNum);
});

function renderReviews(pageNum) {
    let baseUrl = $('#base_url').val();
    let spaceId = $('#space_id').val();
    $.ajax({
        type: 'POST',
        url: `${baseUrl}ajax/spaces/${spaceId}/reviews`,
        data: {"page_num": pageNum},
        success: function (html) {
            $("#review_results").html(html);
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#review_results").offset().top - 100
            }, 0);
        },
        error: function (result) {
            $("#review_results").html("<p>There is an issue fetching reviews</p>");
        },
        fail: (function (status) {
            $("#review_results").html("<p>There is an issue fetching reviews</p>");
        }),
        beforeSend: function (d) {
            //$('#reviews').html("<center><strong style='color:red'>Please Wait...<br><img height='25' width='120' src='<?php echo base_url();?>img/ajax-loader.gif' /></strong></center>");
        }

    });
}