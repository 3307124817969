export interface GeoIPResponse {
  city: string;
  country: string;
  region: string;
  latitude: string;
  longitude: string;
  ip: string;
}

let ipFetched: GeoIPResponse | undefined;

export async function fetchGeoIP(): Promise<GeoIPResponse | null> {
  if (ipFetched) {
    return ipFetched;
  }

  const resp = await fetch('/cf/ip');
  if (!resp.ok) {
    return null;
  }
  ipFetched = await resp.json() as GeoIPResponse;
  return ipFetched;
}
