import 'lightgallery/dist/css/lightgallery.css';

requestAnimationFrame(async () => {
    await import('../vendor/lightgallery');

    $('#srcset').lightGallery({
        selector: '.item',
        preload: 1,
        fullScreen: false,
        download: false,
        autoplay: false,
        zoom: false,
        actualSize: false,
        share: false,
        toogleThumb: false,
        autoplayControls: false,
    });
});